.select {
  width: 200px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.select li {
  margin: 0 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.select li:hover {
  background-color: #eee;
}

.body {
  padding-left: 20px;
}

.text-area {
  width: 540px;
  height: 250px
}

.scrollable-select {
  max-height: 100px;
  overflow-y: scroll;
}


.customer-name {
  color: blue;
}

.employee-name {
  background-color: red;
  color: white;
}

mark {
  background-color: yellow;
  color: black;
}